import React, { useEffect, useState } from 'react';
import { SVG } from '@agemo/common/components';
import { SliceConfig } from '@agemo/toolkit/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const Footer = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return (
    <div>
      <SliceConfig config={{ backgroundColor: { hex: 'var(--color-white)' } }}>
        <SVG svg="wordmark" className={styles.wordmark} />
        <div className={cn('label', styles.bottomText)}>
          <p>© Codewords {new Date().getFullYear()}</p>
          <p>
            Made with{' '}
            <a
              className={styles.lamLink}
              href="https://www.loveandmoney.agency/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Love + Money
            </a>
          </p>
        </div>
      </SliceConfig>
    </div>
  );
};

export default Footer;
