// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mediaContainer = "styles-module--mediaContainer--3f048";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textContainer = "styles-module--textContainer--2214e";
export var video = "styles-module--video--f758d";