import React from 'react';
import { SliceConfig } from '@agemo/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@agemo/common/components';
import { IHero } from '@agemo/sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './styles.module.scss';

interface IProps {
  data: IHero;
}

const Hero = ({
  data: { title, sliceConfig, media: mediaArray, caption }
}: IProps) => {
  const media = mediaArray[0];

  return (
    <SliceConfig
      config={{
        ...sliceConfig,
        backgroundColor: {
          hex: 'var(--theme-bg-color)'
        },
        textColor: {
          hex: 'var(--theme-text-color)'
        }
      }}
    >
      <LayoutProvider grid>
        <div className={styles.textContainer}>
          {caption && (
            <IntersectionAnimation>
              <h1 className="label">{caption}</h1>
            </IntersectionAnimation>
          )}

          <IntersectionAnimation>
            <h1 className="d1">{title}</h1>
          </IntersectionAnimation>
        </div>

        <div className={styles.mediaContainer}>
          {media._type === 'altImage' && (
            <GatsbyImage
              alt={media.altText || ''}
              image={media.asset.gatsbyImageData}
            />
          )}

          {media._type === 'videoFile' && (
            <video
              className={styles.video}
              autoPlay
              playsInline
              loop
              muted
              src={media.file.asset.url}
            />
          )}

          {media._type === 'videoUrl' && (
            <video
              className={styles.video}
              autoPlay
              playsInline
              loop
              muted
              src={media.url}
            />
          )}
        </div>
      </LayoutProvider>
    </SliceConfig>
  );
};

export default Hero;
