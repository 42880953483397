import React, { useEffect, useState } from 'react';
import { EditableTextField } from '@agemo/toolkit/components';
import { Button, FileUpload } from '@agemo/common/components';
import { SVG } from '@agemo/common/components';
import cn from 'classnames';
import { fileToBase64 } from '@agemo/common/utils';
import {
  INewFontApiData,
  INewSanityFileReqBody,
  INewSanityFileResBody
} from '@agemo/toolkit/api';
import { useASG } from '@agemo/toolkit/hooks';
import * as styles from './styles.module.scss';

type TState =
  | `idle`
  | `creating`
  | `uploadingFontFile`
  | `creatingFontDocument`;

interface IFormValues {
  title: string;
  fontWeight: string;
  woff2: File | null;
  woff: File | null;
}

interface IFormErrors {
  title: boolean;
  fontWeight: boolean;
  woff2: boolean;
  woff: boolean;
}

const CreateNewFont = () => {
  const initialFormValues: IFormValues = {
    title: ``,
    fontWeight: ``,
    woff2: null,
    woff: null
  };

  const initialFormErrors: IFormErrors = {
    title: false,
    fontWeight: false,
    woff2: false,
    woff: false
  };

  const [state, setState] = useState<TState>(`idle`);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const isSubmitting =
    state === `uploadingFontFile` || state === `creatingFontDocument`;

  const { setFonts } = useASG();

  useEffect(() => {
    setFormErrors(initialFormErrors);
  }, [formValues]);

  useEffect(() => {
    if (state === `idle`) {
      setFormValues(initialFormValues);
      setFormErrors(initialFormErrors);
    }
  }, [state]);

  const isFormValid = () => {
    const newFormErrors: IFormErrors = { ...formErrors };

    if (!formValues.title) {
      newFormErrors.title = true;
    }
    if (!formValues.fontWeight) {
      newFormErrors.fontWeight = true;
    }
    if (!formValues.woff) {
      newFormErrors.woff = true;
    }
    if (!formValues.woff2) {
      newFormErrors.woff2 = true;
    }

    setFormErrors(newFormErrors);

    if (Object.values(newFormErrors).some((error) => error)) {
      return false;
    }

    return true;
  };

  const createNewFont = async () => {
    const { fontWeight, title, woff, woff2 } = formValues;

    if (!woff || !woff2) {
      throw new Error(`Missing font files`);
    }

    try {
      const woffBase64 = await fileToBase64(woff);
      const woff2Base64 = await fileToBase64(woff2);

      // Upload font files and retrive IDs
      setState(`uploadingFontFile`);

      const woffFileData: INewSanityFileReqBody = {
        base64: woffBase64,
        filename: woff.name
      };
      const woff2FileData: INewSanityFileReqBody = {
        base64: woff2Base64,
        filename: woff2.name
      };

      const woffResponse = await fetch(`/api/create-sanity-file`, {
        method: `POST`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(woffFileData)
      });
      const woff2Response = await fetch(`/api/create-sanity-file`, {
        method: `POST`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(woff2FileData)
      });

      const woffData: INewSanityFileResBody = await woffResponse.json();
      const woff2Data: INewSanityFileResBody = await woff2Response.json();

      // Create new font document
      setState(`creatingFontDocument`);

      const fontApiData: INewFontApiData = {
        title,
        fontWeight,
        woffFileId: woffData._id,
        woff2FileId: woff2Data._id
      };

      const response = await fetch(`/api/create-sanity-font`, {
        method: `POST`,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fontApiData)
      });
      const data = await response.json();

      if (data.statusCode === 500) {
        throw new Error(`Something went wrong submitting form: ${data.body}`);
      }

      setFonts((prev) => [
        ...prev,
        {
          fontWeight: data.fontWeight,
          title: data.title,
          woff: {
            asset: {
              url: woffData.url,
              originalFilename: woffData.originalFilename,
              _id: woffData._id
            }
          },
          woff2: {
            asset: {
              url: woff2Data.url,
              originalFilename: woff2Data.originalFilename,
              _id: woff2Data._id
            }
          },
          _id: data._id
        }
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid()) return;

    try {
      await createNewFont();
      setState(`idle`);
    } catch (err) {
      console.error(err);
      setState(`creating`);
    }
  };

  const uploadStatusText: () => string = () => {
    if (state === `uploadingFontFile`) {
      return `1/2 Uploading font file...`;
    }
    if (state === `creatingFontDocument`) {
      return `2/2 Creating font document...`;
    }
    return ``;
  };

  /** ========================================================================
   * Render
   */

  if (state === `idle`) {
    return (
      <button
        className={cn(styles.container, styles.plusButton)}
        onClick={() => setState(`creating`)}
      >
        <SVG svg="plus" className={styles.plusIcon} />
      </button>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.formField}>
          <label>
            Title:
            <EditableTextField
              error={formErrors.title}
              value={formValues.title}
              isEditing={state === `creating`}
              onChange={(value) =>
                setFormValues((prev) => ({
                  ...prev,
                  title: value
                }))
              }
              className={styles.input}
            />
          </label>
        </div>
        <div className={styles.formField}>
          <label>
            Font weight:
            <EditableTextField
              error={formErrors.fontWeight}
              value={formValues.fontWeight}
              isEditing={state === `creating`}
              onChange={(value) =>
                setFormValues((prev) => ({
                  ...prev,
                  fontWeight: value
                }))
              }
              className={styles.input}
            />
          </label>
        </div>

        <div className={styles.formField}>
          <label>
            WOFF2 File
            <FileUpload
              hasError={formErrors.woff2}
              disabled={isSubmitting}
              acceptedFileFormats=".woff2"
              setFile={(file) =>
                setFormValues((prev) => ({ ...prev, woff2: file }))
              }
            />
          </label>
        </div>
        <div className={styles.formField}>
          <label>
            WOFF File
            <FileUpload
              hasError={formErrors.woff}
              disabled={isSubmitting}
              acceptedFileFormats=".woff"
              setFile={(file) =>
                setFormValues((prev) => ({ ...prev, woff: file }))
              }
            />
          </label>
        </div>

        <div className={styles.buttonAndProgress}>
          <Button loading={isSubmitting} onClick={handleSubmit} text="Create" />

          {isSubmitting && (
            <div className={styles.progressBarAndStatus}>
              <p className={cn(`caption`)}>{uploadStatusText()}</p>
              <div className={styles.progressBar}>
                <div
                  className={cn(styles.progressBarProgress, {
                    [styles.halfway]: state === `creatingFontDocument`
                  })}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <Button
          disabled={isSubmitting}
          onClick={() => setState(`idle`)}
          text="Cancel"
        />
      </div>
    </div>
  );
};

export default CreateNewFont;
