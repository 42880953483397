module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Agemo Toolkit","short_name":"Agemo Toolkit","description":"Agemo provides exceptional childcare & early education services through 38 long day care centres around Australia.","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/assets/images/manifest-icon.png","include_favicon":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"3065916a268285ca15c7521b3f8ac026"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-V20Q9QBWMT","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"() => {\n          const queryStrings =\n            (document && document.location && document.location.search) || {};\n\n          window.utms = queryStrings;\n\n          const queriesObj = queryStrings\n            ? Object.fromEntries(new URLSearchParams(queryStrings))\n            : {};\n\n          return {\n            platform: `gatsby`,\n            ...queriesObj\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
