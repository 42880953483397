import React from 'react';
import { SliceConfig } from '@agemo/toolkit/components';
import { IHomepageAtf } from '@agemo/sanity';
import cn from 'classnames';
import { IntersectionAnimation } from '@agemo/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: IHomepageAtf;
}

const HomepageAtf = ({
  data: { bottomText, topText, videoDesktop, videoMobile }
}: IProps) => {
  return (
    <div className={styles.container}>
      <video
        autoPlay
        muted
        playsInline
        loop
        src={videoDesktop.file.asset.url}
        className={styles.desktopVideo}
      />
      <video
        autoPlay
        muted
        playsInline
        loop
        src={videoMobile.file.asset.url}
        className={styles.mobileVideo}
      />
      <SliceConfig
        config={{
          slicePadding: {
            paddingTop: 'small',
            paddingBottom: 'small'
          },
          backgroundColor: {
            hex: 'var(--theme-bg-color)'
          },
          textColor: {
            hex: 'var(--theme-text-color)'
          }
        }}
      >
        <div className={styles.content}>
          <h1 className={cn('d1', styles.headerText)}>
            <IntersectionAnimation delay={150}>
              <span>{topText}</span>
            </IntersectionAnimation>
            <IntersectionAnimation delay={450}>
              <span>{bottomText}</span>
            </IntersectionAnimation>
          </h1>
        </div>
      </SliceConfig>
    </div>
  );
};

export default HomepageAtf;
