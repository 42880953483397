import React from 'react';
import { SliceConfig } from '@agemo/toolkit/components';
import { IntersectionAnimation } from '@agemo/common/components';
import { IFigmaFrame } from '@agemo/sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: IFigmaFrame;
}

const FigmaFrame = ({ data: { url, sliceConfig } }: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <IntersectionAnimation>
        <div className={styles.frameContainer}>
          <iframe className={styles.iframe} allowFullScreen src={url} />
        </div>
      </IntersectionAnimation>
    </SliceConfig>
  );
};

export default FigmaFrame;
