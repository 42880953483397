import React from 'react';
import { TextInput } from '@agemo/common/components';
import { IComponentSchemaField, usePlayground } from '@agemo/toolkit/hooks';

const PgTextInput = () => {
  const schemaFields: IComponentSchemaField[] = [
    {
      title: `placeholder`,
      type: `textInput`
    },
    {
      title: `value`,
      type: `textInput`
    },
    {
      title: `label`,
      type: `textInput`
    },
    {
      title: `hint`,
      type: `textInput`
    },
    {
      title: `warningMessage`,
      type: `textInput`
    },
    {
      title: `hasSearchIcon`,
      type: `toggle`
    },
    {
      title: `hasError`,
      type: `toggle`
    },
    {
      title: `required`,
      type: `toggle`
    },
    {
      title: `disabled`,
      type: `toggle`
    },
    {
      title: `textarea`,
      type: `toggle`
    }
  ];

  const { config, renderPlayground } = usePlayground(schemaFields);

  const previewComponent = <TextInput {...config} />;

  return <>{renderPlayground(previewComponent, `components`)}</>;
};

export default PgTextInput;
