import { ITypestyle } from '@agemo/sanity';
import { useASG } from '@agemo/toolkit/hooks';

const getTypestyleCss = (typestyles: ITypestyle[] = []) => {
  const MIN_TABLET_SIZE = 768;
  const MIN_DESKTOP_SIZE = 1024;

  const { getFontById } = useASG();

  let css = ``;

  typestyles.forEach((typestyle) => {
    const font = getFontById(typestyle.font._id);
    if (!font) return;

    css += `
				.${typestyle.className} {
					font-family: ${font?.title};
					font-size: ${typestyle.fontSizes.mobile};
					line-height: ${typestyle.lineHeight};
					letter-spacing: ${typestyle.letterSpacing};
					text-transform: ${typestyle.uppercase ? 'uppercase' : 'none'};
					text-decoration: ${typestyle.underline ? 'underline' : 'none'};
				}
				@media (min-width: ${MIN_TABLET_SIZE}px) {
					.${typestyle.className} {
						font-size: ${typestyle.fontSizes.tablet};
					}
				}
				@media (min-width: ${MIN_DESKTOP_SIZE}px) {
					.${typestyle.className} {
						font-size: ${typestyle.fontSizes.desktop};
					}
				}
			`;
  });

  return css;
};

export default getTypestyleCss;
