import React from 'react';
import { IResourceLink } from '@agemo/sanity';
import cn from 'classnames';
import { Button } from '@agemo/common/components';
import * as styles from './styles.module.scss';

interface IProps extends IResourceLink {
  className?: string;
}

const ResourceLink = ({ title, buttonText, url, className }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(`h3`, styles.title)}>{title}</div>
      <div className={styles.border} />
      <Button href={url} text={buttonText} />
    </div>
  );
};

export default ResourceLink;
