import React from 'react';
import { IUniversalLinkToolkit, IUniversalLinkWebsite } from '@agemo/sanity';
import { Button, type IButton } from '@agemo/common/components';

type UniversalLink = IUniversalLinkToolkit | IUniversalLinkWebsite;

type UniversalLinkExtended = {
  [K in keyof UniversalLink]: UniversalLink[K];
};

type TRequiredButtonProps = Omit<
  IButton,
  'text' | 'buttonType' | 'to' | 'href' | 'onClick' | 'display' | 'loading'
>;

type TProps = {
  link: UniversalLinkExtended;
} & TRequiredButtonProps;

const UniversalLink = ({
  link: universalLink,
  className,
  disabled,
  fluid,
  iconLeft,
  iconRight,
  variant,
  theme
}: TProps) => {
  const buttonProps = {
    className,
    disabled,
    fluid,
    iconLeft,
    iconRight,
    variant,
    theme
  };

  const link = universalLink.link[0];
  const text = universalLink.text;

  switch (link._type) {
    case `externalUrl`:
      return <Button text={text} href={link.url} {...buttonProps} />;

    case `referenceToolkitPage`:
    case `referenceWebsitePage`:
      return (
        <Button
          text={text}
          to={`/${link.page.slug.current}`}
          {...buttonProps}
        />
      );

    default:
      return null;
  }
};

export default UniversalLink;
