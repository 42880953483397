import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { IColorPairGrid } from '@agemo/sanity';
import { ColorGridTile, SliceConfig } from '@agemo/toolkit/components';
import { IntersectionAnimation } from '@agemo/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: IColorPairGrid;
}

export interface INewColorDetails {
  title: string;
  hex: string;
}

const ColorPairGrid = ({ data: { colorPairs, sliceConfig } }: IProps) => {
  const [hasInitialAnimationCompleted, setHasInitialAnimationCompleted] =
    useState(false);

  const ANIMATION_STAGGER = 150;

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasInitialAnimationCompleted(true);
    }, ANIMATION_STAGGER * colorPairs.length * 2);

    return () => clearTimeout(timeout);
  }, [colorPairs.length]);

  return (
    <SliceConfig config={sliceConfig}>
      <div ref={ref}>
        {colorPairs.map(({ color1, color2 }, i) => (
          <div className={styles.tilePair} key={i}>
            <IntersectionAnimation
              animation="fadeGrow"
              trigger={inView}
              delay={
                hasInitialAnimationCompleted ? 0 : ANIMATION_STAGGER * (i * 2)
              }
              className={styles.tileContainer}
            >
              <ColorGridTile
                title={color1.title}
                hex={color1.hex}
                textColor={color2.hex}
                pantone={color1.pantone}
                pairTile
              />
            </IntersectionAnimation>

            <IntersectionAnimation
              animation="fadeGrow"
              trigger={inView}
              delay={
                hasInitialAnimationCompleted
                  ? 0
                  : ANIMATION_STAGGER * (i * 2 + 1)
              }
              className={styles.tileContainer}
            >
              <ColorGridTile
                title={color2.title}
                hex={color2.hex}
                textColor={color1.hex}
                pantone={color2.pantone}
                pairTile
              />
            </IntersectionAnimation>
          </div>
        ))}
      </div>
    </SliceConfig>
  );
};

export default ColorPairGrid;
