import React, { useEffect, useState } from 'react';
import { SliceConfig } from '@agemo/toolkit/components';
import { TextInput } from '@agemo/common/components';
import { useApp } from '@agemo/toolkit/hooks';
import { IPassword } from '@agemo/sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: IPassword;
}

const Password = ({ data: { password } }: IProps) => {
  const [input, setInput] = useState(``);
  const [hasError, setHasError] = useState(false);

  const { setPagePasswordStatus, pathname } = useApp();

  const handleSubmit = () => {
    if (input === password) {
      setPagePasswordStatus((prev) => ({
        ...prev,
        [pathname]: `unlocked`
      }));
      return;
    }
    setHasError(true);
  };

  useEffect(() => {
    setHasError(false);
  }, [input]);

  return (
    <SliceConfig
      config={{
        textColor: {
          hex: `var(--color-oakwrights-blue)`
        },
        slicePadding: {
          paddingTop: `none`
        }
      }}
    >
      <p className={cn(`b1`, styles.description)}>
        The rest of this page is password protected
      </p>

      <TextInput
        className={styles.input}
        placeholder="Enter password..."
        password
        value={input}
        onChange={setInput}
        hasError={hasError}
        onClick={handleSubmit}
        onEnter={handleSubmit}
        warningMessage="Incorrect password"
      />
    </SliceConfig>
  );
};

export default Password;
