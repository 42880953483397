// extracted by mini-css-extract-plugin
export var container = "styles-module--container--67838";
export var content = "styles-module--content--05323";
export var desktop = "1340px";
export var desktopVideo = "styles-module--desktopVideo--ce908";
export var giant = "2200px";
export var headerText = "styles-module--headerText--cc008";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var mobileVideo = "styles-module--mobileVideo--c3067";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";