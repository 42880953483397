import React, { CSSProperties } from 'react';
import { PortableText } from '@portabletext/react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  rawText: any;
  style?: CSSProperties;
  className?: string;
}

const customComponents = (style?: CSSProperties) => ({
  listItem: {
    number: ({ children }: any) => {
      const nodeStyle = children.props.node.style;
      return (
        <li className={cn(styles.orderedListItem, nodeStyle)}>{children}</li>
      );
    },
    bullet: ({ children }: any) => {
      const nodeStyle = children.props?.node.style || 'b2';
      return (
        <li className={cn(styles.unorderedListItem, nodeStyle)}>{children}</li>
      );
    }
  },
  block: {
    normal: ({ children }: any) => <p style={style}>{children}</p>,
    d1: ({ children }: any) => (
      <h2 style={style} className="d1">
        {children}
      </h2>
    ),
    d2: ({ children }: any) => (
      <h2 style={style} className="d2">
        {children}
      </h2>
    ),
    d3: ({ children }: any) => (
      <h2 style={style} className="d3">
        {children}
      </h2>
    ),
    h1: ({ children }: any) => (
      <h2 style={style} className="h1">
        {children}
      </h2>
    ),
    h2: ({ children }: any) => (
      <h3 style={style} className="h2">
        {children}
      </h3>
    ),
    h3: ({ children }: any) => (
      <h4 style={style} className="h3">
        {children}
      </h4>
    ),
    h4: ({ children }: any) => (
      <h4 style={style} className="h4">
        {children}
      </h4>
    ),
    b1: ({ children }: any) => (
      <p style={style} className="b1">
        {children}
      </p>
    ),
    b2: ({ children }: any) => (
      <p style={style} className="b2">
        {children}
      </p>
    ),
    label: ({ children }: any) => (
      <p style={style} className="label">
        {children}
      </p>
    ),
    caption: ({ children }: any) => (
      <p style={style} className="caption">
        {children}
      </p>
    ),
    ['button-text']: ({ children }: any) => (
      <p style={style} className="button-text">
        {children}
      </p>
    )
  },
  marks: {
    link: ({ children, value }: any) => {
      const rel = value.href.startsWith('/')
        ? undefined
        : 'noreferrer noopener';
      return (
        <a style={{ textDecoration: `underline` }} href={value.href} rel={rel}>
          {children}
        </a>
      );
    }
  }
});

const PortableTextRenderer = ({ rawText, className, style }: IProps) => (
  <div className={cn(styles.container, className)}>
    <PortableText value={rawText} components={customComponents(style)} />
  </div>
);

export default PortableTextRenderer;
