import React from 'react';
import * as styles from './styles.module.scss';
import { IComponentSchemaField } from '@agemo/toolkit/hooks';
import Configuration from './components/Configuration';
import Canvas from './components/Canvas';
import SCSSInspector from './components/SCSSInspector';
import TSXInspector from './components/TSXInspector';

export type TInspectorDirectory = `components` | `slices`;

interface IProps {
  componentSchema: IComponentSchemaField[];
  config: any;
  updateConfig: (field: string, value: any) => void;
  previewComponent: JSX.Element;
  directory: TInspectorDirectory;
}

const PlaygroundWrapper = ({
  componentSchema,
  previewComponent,
  config,
  updateConfig,
  directory
}: IProps) => {
  return (
    <div className={styles.container}>
      <Configuration
        componentSchema={componentSchema}
        config={config}
        updateConfig={updateConfig}
      />
      <Canvas previewComponent={previewComponent} />
      <SCSSInspector
        directory={directory}
        componentName={previewComponent.type.name}
      />
      <TSXInspector
        directory={directory}
        componentName={previewComponent.type.name}
      />
    </div>
  );
};

export default PlaygroundWrapper;
